<!--
 * @Description: 费用补贴
 * @Author: 琢磨先生
 * @Date: 2022-08-16 04:30:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-11-20 16:48:27
-->
  
<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          style="width: 240px"
          filterable
          :props="props"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="form.type">
          <el-select v-model="query.type" clearable style="width: 150px">
            <el-option
              v-for="item in enums.subsidy_type"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="费用项">
        <el-select v-model="query.expense_item_id" clearable style="width: 150px">
          <el-option v-for="item in type_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-select v-model="query.status" clearable style="width: 150px">
          <el-option
            v-for="item in enums.subsidy_status"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="删除?">
        <el-select v-model="query.is_del" placeholder="" :clearable="true" style="width:100px;">
          <el-option label="正常" value="false"></el-option>
          <el-option label="已删除" value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
      >新增</el-button>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" @sort-change="onSort" border :row-style="handleRowStyle">
      <!-- <el-table-column prop="id" label="编号" width="80"></el-table-column> -->
      <el-table-column prop="type_text" label="类型" width="100"></el-table-column>
      <el-table-column prop="expense_item_name" label="费用项" width="140"></el-table-column>
      <el-table-column prop="store.landlord.name" label="业主" width="160"></el-table-column>
      <el-table-column prop="store.house_no" label="房号" min-width="200"></el-table-column>
      <el-table-column label="日期" prop="date" width="120"></el-table-column>
      <el-table-column label="金额(元)" width="140">
        <template #default="scope">
          ￥{{ scope.row.type == 10 ? "-" : ""
          }}{{ scope.row.decimal_amount }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" width="140">
        <template #default="scope">
          <div>{{ scope.row.status_text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="审核结果" width="100">
        <template #default="scope">
          <div>
            <el-tag type="info" v-if="scope.row.check_result == 0" size="small"
              >待审核</el-tag
            >
            <el-tag
              type="success"
              v-if="scope.row.check_result == 2"
              size="small"
              >有效</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.check_result == 4"
              size="small"
              >无效</el-tag
            >
          </div>
        </template>
      </el-table-column>-->
      <el-table-column label="备注" prop="remark" min-width="200"></el-table-column>
      <el-table-column label="创建人" prop="emp.chs_name" width="100"></el-table-column>
      <el-table-column prop="create_at" label="创建时间" width="180"></el-table-column>
      <el-table-column label="删除？" width="180">
        <template #default="scope">{{scope.row.is_deleted?'已删除':''}} / {{ scope.row.deleted_at }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="!scope.row.is_deleted && is_can_edit"
          >修改</el-button>
          <el-popconfirm
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="!scope.row.is_deleted && is_can_del"
          >
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    draggable
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="类型">
        <el-radio-group v-model="form.type">
          <el-radio
            v-for="item in enums.subsidy_type"
            :key="item.value"
            :label="item.value"
          >{{ item.text }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="费用项目" prop="expense_item_id">
        <el-select v-model="form.expense_item_id" @change="typeChange">
          <el-option v-for="item in type_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房间" prop="storeIds">
        <el-cascader
          :options="huxing_list"
          v-model="form.storeIds"
          :props="props"
          style="width: 300px"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="金额(元)" prop="decimal_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_amount" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="备注">
        <el-col :span="16">
          <el-input v-model="form.remark" placeholder type="textarea"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="图片">
        <upload-gallery :pictures="form.pic_urls" @change="uploadChange"></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import uploadGallery from "../../psd/upload_gallery.vue";
import common_api from "@/http/common_api";
// import SubsidyCheck from "./subsidy_check.vue";
import huxing_api from "@/http/huxing_api";
import expense_api from "@/http/expense_api";

export default {
  components: {
    uploadGallery,
    // SubsidyCheck,
  },
  data() {
    return {
      loading: false,
      title: "",
      //显示导入弹框
      visibleDialog: false,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      form: {},
      rules: {
        type: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        decimal_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        expense_item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //补贴类型
      type_list: [],
      //房屋列表
      storeList: [],
      enums: {},
      huxing_list: [],
      props: {
        label: "name",
        value: "id",
      },
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.type_list = res.data;
      }
    });

    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    huxing_api.get_cascader().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            id: x.id,
            name: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              id: item.id,
              name: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });

    this.loadData();
    this.is_can_edit = this.$power("seller/v1/subsidy/edit");
    this.is_can_del = this.$power("seller/v1/subsidy/del");
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.query.store_id = "";
      if (this.query.storeIds) {
        this.query.store_id =
          this.query.storeIds[this.query.storeIds.length - 1];
      }
      this.$http.post("seller/v1/subsidy", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = Object.assign(
        {
          type: 0,
        },
        item
      );
      if (item) {
        this.form.storeIds = [item.store.group_id, item.store.id];
        delete this.form.store;
        delete this.form.emp;
        delete this.form.del_emp;
      }
      this.visibleDialog = true;
    },

    /**
     * 审核费用支出
     * @param {*} item
     */
    onCheck(item) {
      console.log(item);
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/subsidy/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 类型选择更改
     */
    typeChange() {
      var item = this.type_list.find((o) => o.id == this.form.expense_item_id);
      if (item) {
        this.form.expense_item_name = item.name;
      }
    },

    /**
     * 房号选择更改
     */
    changeStore() {
      // this.calcAA();
    },

    /**
     * 远程搜索房源房号
     * @param {*} q
     */
    remoteStore(q) {
      common_api.get_housestore(q).then((res) => {
        if (res.code == 0) {
          this.storeList = res.data;
        }
      });
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.form.store_id = this.form.storeIds[this.form.storeIds.length - 1];
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/subsidy/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 审核状态回调
     */
    checkChange() {
      this.onSearch();
    },

    handleRowStyle(item) {
      if (item.row.is_deleted) {
        return "color:#f56c6c;";
      }
    },
  },
};
</script>

<style scoped>
</style> 